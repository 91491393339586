import React from 'react';
import Auth from './../modules/auth/Auth';
//scss
import './../scss/_MainBar.scss';

const main_bar_options = [
    { text: "סטטיסטיקות", route: "/" },
    { text: "כניסות", route: "/lobby", otherRoutes: ["admin/quiz-details"] },
    // { text: "שאלות", route: "/questions" }
];

function MainBar(props) {

    const logout = () => {
        window.history.pushState({}, document.title, "/");
        window.location.pathname = "/";
    }

    let currentPath = props.history.location.pathname.split("/");
    currentPath = currentPath && currentPath[1] || "";

    return (
        <div className="main-bar-container">
            <div className="page-options">
                {main_bar_options.map((element, index) => {
                    return (
                        <div
                            key={index}
                            onClick={() => { props.history.push(element.route) }}
                            className={(props.history.location.pathname === element.route
                                || element.otherRoutes && element.otherRoutes.includes(currentPath))
                                ? "page-text chosen" : "page-text"}
                        >
                            {element.text}
                        </div>
                    );
                })}
                <div onClick={() => { Auth.logout(logout); }} className="page-text logout">התנתק</div>
            </div>
            <div className='webTitleDiv'>
                <div className='webTitle'>שאלון לאבחון עצמי</div>
                <img className='linesForBar' src={`./images/lines_for_bar.svg`} alt="line" title="line" />
            </div>
        </div>
    );
}

export default MainBar;
