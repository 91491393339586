import React, { Component } from 'react';
import Auth from './../modules/auth/Auth';
import ValidateTool from '../modules/tools/ValidateTool';
import { Dialog, DialogTitle, Button, DialogContent, DialogActions } from '@material-ui/core';
import GenericTools from './../modules/tools/GenericTools';

//scss
import './../scss/_Login.scss';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            resetPassDialog: false,
            resetPassMsg: "",
            loginMsg: "",
            phoneLogin: ""
        }
        this.handleEmailLogin = this.handleEmailLogin.bind(this);
    }

    async handleEmailLogin(e) {
        e.preventDefault();
        this.setState({ isLoading: true });

        const email = this.refs.email.value;
        const pw = this.refs.pw.value;
        const res = await Auth.login(email, pw, null, {}, `/api/CustomUsers/elogin`);

        if (!res.success) {
            console.log("login failed with error", res.msg);
            this.setState({ loginMsg: res.msg.error.msg, isLoading: false });
            return;
        }

        //if res.success is true
        if (res.user.tfa && res.user.tfa.required) {
            this.setState({ phoneLogin: res.user.tfa.phone, isLoading: false });
            return;
        }

        this.setState({ isLoading: false });

        const pwdResetRequired = res.user && res.user.pwdResetRequired;
        let redirTo = this.props.redirectUrl || "/";
        if (pwdResetRequired) redirTo = "/new-password";
        GenericTools.safe_redirect(redirTo);
    }

    handlePhoneLogin = async (e) => {
        e.preventDefault();

        this.setState({ isLoading: true });
        const pincode = this.refs.pincode.value;

        let loginObj = { pincode, phone: this.state.phoneLogin };
        let [validatePinRes, validatePinErr] = await Auth.superAuthFetch(`/api/CustomUsers/validateTFALoginPincode/`, {
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            method: "POST",
            body: JSON.stringify(loginObj)
        });
        if (validatePinErr) {
            this.setState({
                loginMsg: validatePinErr.error.code || validatePinErr.error.message,
                isLoading: false
            });
            console.log("Error logging-in with pincode", validatePinErr);
            return;
        }
        this.setState({ isLoading: false });
        let redirTo = this.props.redirectUrl || "/";
        redirTo = this.props.basePath + redirTo;
        GenericTools.safe_redirect(redirTo);
    }

    reset = async (e, emailMsg = null) => {
        e.preventDefault();
        let email = this.refs.resetEmailInput.value;
        let [res, err] = await Auth.superAuthFetch(`/api/CustomUsers/reset`, {
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            method: "POST",
            body: JSON.stringify({ email, origin: window.location.origin + (window.location.hash[0] === "#" ? "/#" : "") })
        })

        this.setState({
            resetPassDialog: true,
            resetPassMsg: res ? "נשלחה הודעת אימות לכתובת המייל" : "הכתובת אינה רשומה במערכת"
        });
    }

    componentDidMount() {
        //   Auth.logout(null, '/login')
        // if (Auth.isAuthenticated()) Auth.logout();
    }

    resetPasswordSuccessClick = () => {
        this.setState({ resetPassDialog: false });
        window.history.pushState({}, document.title, "/");
        window.location.pathname = "/";
    }


    render() {
        return (
            <div className='loginPage'>
                <div className="h1h2">
                    <div className='loginTitle'>שאלון לאבחון עצמי</div>
                    <img className='linesForLogin' src={`./images/lines_for_login.svg`} alt="line" title="line" />
                </div>
                <div className='loginBox'>

                    <form onSubmit={!this.state.phoneLogin ? this.handleEmailLogin : this.handlePhoneLogin} id="logForm" className="collapses show form" data-toggle="collapse">
                        {!this.state.phoneLogin ?
                            <div>
                                <div className='form-group'>
                                    <input className="form-control" type='email' ref='email' placeholder='מייל' required />
                                </div>
                                <div className='form-group'>
                                    <input className="form-control" type='password' ref='pw' placeholder='סיסמא' required />
                                    {this.state.loginMsg !== '' && <div className='msg-error'>{this.state.loginMsg}</div>}
                                </div>

                                <div className='form-group'>
                                    {this.state.isLoading ?
                                        <button className='btn loginButton'>שולח קוד...</button> :
                                        <button onClick={this.handleEmailLogin} type='button' className='btn loginButton'>שלח קוד</button>
                                    }
                                </div>
                            </div>
                            :
                            <div>
                                <div className="pincode-phone-text">
                                    <span className="text">קוד אימות נשלח למספר</span>
                                    <span className="phone"> {this.state.phoneLogin}</span>
                                </div>
                                <div>
                                    <div className='form-group'>
                                        <input className="form-control" type='string' ref='pincode' placeholder='קוד אימות' required />
                                        {this.state.loginMsg && <div className='msg-error'>{this.state.loginMsg}</div>}
                                    </div>
                                </div>

                                <div className='form-group'>
                                    {this.state.isLoading ?
                                        <button className='btn loginButton'>מתחבר...</button> :
                                        <button onClick={this.handlePhoneLogin} type='button' className='btn loginButton'>היכנס</button>
                                    }
                                </div>
                            </div>
                        }
                    </form>

                    {/* form reset section */}
                    <div id="resetPassDiv" className="collapse collapses">
                        <form onSubmit={this.reset}>
                            <div className='form-group'>
                                <input ref="resetEmailInput" id="reset" type="email" className="form-control login_input" placeholder="Email" required />
                            </div>
                            <div className='form-group'>
                                <button type="submit" className="btn loginButton" >
                                    אפס סיסמה
                                </button>
                            </div>
                        </form>
                    </div>
                    {/* end of form reset section */}

                    <p>
                        <button className="forget-btn btn-link login_input" id="toggle" type="button" data-toggle="collapse" data-target=".collapses" aria-expanded="false" aria-controls="resetPassDiv logForm pincodeInputDiv" onClick={(event) => {
                            event.target.innerHTML = event.target.innerHTML === "התחבר" ? 'שכחת סיסמה?' : "התחבר"
                        }}>
                            שכחתי סיסמה
                            </button>
                    </p>
                </div>

                {/* dialog reset section */}
                <Dialog open={this.state.resetPassDialog} aria-labelledby="reset-modal">
                    <DialogTitle id="reset-modal" className="float-right" >
                        <p style={{ float: "right", margin: "0" }}>שינוי סיסמה</p>
                    </DialogTitle>

                    <DialogContent>
                        {this.state.resetPassMsg}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.resetPasswordSuccessClick} children="סבבה" color="primary" />
                    </DialogActions>
                </Dialog>
                {/* End of dialog reset section */}
            </div>
        )
    }
}

window.ValidateTool = ValidateTool;
export default Login;
