
import React, { useEffect } from 'react';

//scss
import './../scss/_Lobby.scss';

const NoInternet = () => {

    useEffect(() => {
        if (navigator.onLine) window.location.pathname = "/";
    }, []);

    const team = ["ש. רבינוביץ", "מ. בן שימול"];

    return (
        <div className="no-internet-container">
            <br /><br /><br /><br />
            <img id="no-internet" src={`./images/sad.svg`} alt="עצוב - אין אינטרנט" />
            <h1 className="title-no-internet" > ...אופס </h1>
            <p>
                לא נימצא חיבור לאינטרנט.<br />
                כדאי לבדוק את החיבור ולנסות שנית.
           </p>
{/* 
            <div className="team-names">
                ה-מ-פ-ת-ח-ו-ת:
                {
                    team.map(name => {
                        return <div key={name}>
                            {name}
                        </div>;
                    })
                }
            </div> */}
        </div>
    );
}

export default NoInternet;