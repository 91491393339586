import { observable, decorate, action, computed, runInAction } from 'mobx';
import Auth from './../modules/auth/Auth';
import Consts from './../consts/Consts';

class DialogStore {
    allDialogs = null;
    dialogsCount = 0;
    callToDialogs = true;
    currentDialog = null;
    errMsg = null;
    filters = [];
    hasMoreDialogs = true;

    async getAllDialogs() {
        let filter = this.filters.find(filter => filter.filterType === "date")
        if (!filter) return;
        const [res, err] = await Auth.superAuthFetch(`/api/Dialogs/getAllDialogs?dialogsCount=${this.dialogsCount}&filter=${JSON.stringify(filter)}&filters=${JSON.stringify(this.filters)}`, null, true);
        if (err) { console.log(err, 'err on get all dialogs') }

        runInAction("Update this's after fetching Data", () => {
            if (!this.allDialogs) this.allDialogs = res
            else this.allDialogs.push(...res);
            this.callToDialogs = false;
            if (this.dialogsCount === this.allDialogs?.length) this.hasMoreDialogs = false
            this.dialogsCount = this.allDialogs?.length || 0
        });
    }

    get AllDialogs() {
        if (this.callToDialogs) {
            this.getAllDialogs();
        }
        return this.allDialogs;
    }

    get compCurrentDialog() { return this.currentDialog; }
    set compCurrentDialog(dialog) { this.currentDialog = dialog; }

    async getCurrentDialog(dialogId) {
        let storageErrMsg = null;
        let [res, err] = await Auth.superAuthFetch(`/api/Dialogs/getCurrentDialog?dialogId=${dialogId}`, null, false);
        if (err) {
            console.log("err getting current dialog", err);
            storageErrMsg = err.error && err.error.message === "NOT_VALID_DIALOG_ID" ? err.error.message : 'NETWORK_CONNECTION_ERROR';
        } else if (res && res.length === 0) { storageErrMsg = 'DIALOG_NOT_FOUND'; }

        runInAction("Update this's after fetching Data", () => {
            this.currentDialog = res;
            this.errMsg = storageErrMsg;
        });
    }

    get filterDialogs() {
        let victimFilter = this.filters.find(filter => filter.filterType === "victim")
        let offenderFilter = this.filters.find(filter => filter.filterType === "offender")
        let clientFilter = this.filters.find(filter => filter.filterType === "client")

        let filterDialogs = this.allDialogs && this.allDialogs.filter(dialog => {
            if (victimFilter && victimFilter.filter !== dialog.victim) return false
            if (offenderFilter && offenderFilter.filter !== dialog.offender) return false
            if (clientFilter && clientFilter.filter !== dialog.client) return false

            return true
        });

        return filterDialogs;
    }

}

decorate(DialogStore, {
    allDialogs: observable,
    callToDialogs: observable,
    AllDialogs: computed,
    currentDialog: observable,
    errMsg: observable,
    getCurrentDialog: action,
    compCurrentDialog: computed,
    filterDialogs: computed,
    filters: observable,
    dialogsCount: observable,
    hasMoreDialogs: observable
});

export default new DialogStore();