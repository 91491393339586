import { observable, decorate, action, computed, runInAction } from 'mobx';
import Auth from './../modules/auth/Auth';
import Consts from './../consts/Consts';

class UserStore {
    client = null

    async getClient() {
        if (this.client) return
        const [res, err] = await Auth.superAuthFetch(`/api/CustomUsers/getClient`, null, true);
        if (err) { console.log(err, 'err on get all dialogs') }

        this.client = res?.client
    }
}

decorate(UserStore, {
    client: observable,
    getClient: action
});

export default new UserStore();