import React from 'react';
import GenericTools from './../modules/tools/GenericTools';
import ResetPassword from './../modules/auth/client/components/ResetPassword'

//css
import Dialog from '@material-ui/core/Dialog';

class ResetPasswordScene extends ResetPassword {
    constructor(props) {
        super(props);
        this.state = {
            errPass: '',
            errConf: '',
            infoModal: false,
            modalText: ""
        };
    }

    closeErrorSuccessDialog = () => {
        this.setState({ infoModal: false });
        if (!this.state.errorCode) {
            window.history.pushState({}, document.title, "/");
            window.location.pathname = "/";
        }
    }

    render() {
        return (
            <div className="login-with-phone-container reset-pass">
                <div className={this.state.infoModal ? "disableBackground disableBackgroundLogin" : ""}>
                    <img src={`./images/otherIcons/next.svg`}
                        alt="חזרה"
                        title="חזרה"
                        className="onClickPointer back-arrow"
                        onClick={() => GenericTools.safe_redirect("/")}
                    />
                    <div className="reset-title">
                        <h2>איפוס סיסמא</h2>
                    </div>

                    <form className="phone-pin-input" onSubmit={this.submit}>
                        <div className='form-group' >
                            <input
                                ref="pwd"
                                id="password"
                                type="password"
                                className="loginInputStyle"
                                placeholder='הכנס סיסמה חדשה'
                                required />
                            <p style={{ color: "red" }}>{this.state.errPass}</p>
                        </div>
                        <div className='form-group' >
                            <input
                                ref="confPwd"
                                id="passwordConfirm"
                                type="password"
                                className="loginInputStyle"
                                placeholder='הכנס שנית'
                                required />
                            <p style={{ color: "red" }}>{this.state.errConf}</p>
                        </div>
                        <div className='form-group' >
                            <button type="submit" id="resetLoginBtn" className="loginBtn onClickCursor ">
                                שמור סיסמה
                        </button>
                        </div >
                    </form>
                </div>
                <Dialog
                    onClose={this.closeErrorSuccessDialog}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.infoModal}
                >
                    <div className="loginPopup" >
                        <img src={`./images/otherIcons/x-button.svg`}
                            alt="יציאה"
                            title="יציאה"
                            className="x-btn-reset onClickPointer"
                            onClick={this.closeErrorSuccessDialog}
                        />
                        <div className="hebErrMsg" > {this.state.modalText} </div>
                    </div >
                </Dialog>
            </div>
        );
    }
}
export default ResetPasswordScene;
