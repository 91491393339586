import { observable, decorate, action } from 'mobx';
import Auth from '../modules/auth/Auth';

let date = new Date(Date.now());

class FactsStore {
    dangerRatingChartData;
    dangerRatingNumbers;
    quizsNumbers;
    quizeAvgTime;
    moreStatistics;
    violenceTypes;
    activityTimes;

    async getStatistics(chosenMonth, chosenYear, client) {
        Auth.superAuthFetch(`/api/Facts/getDangerRatingChartData?chosenMonth=${chosenMonth}&&chosenYear=${chosenYear}&&client=${client}`, null, true)
            .then(([dangerRatingChartRes, dangerRatingChartErr]) => {
                if (dangerRatingChartErr) { console.log(dangerRatingChartErr, 'err getting dangerRatingChartRes') }
                this.dangerRatingChartData = dangerRatingChartRes;
            });

        Auth.superAuthFetch(`/api/Facts/getQuizsNumbers?chosenMonth=${chosenMonth}&&chosenYear=${chosenYear}&&client=${client}`, null, true)
            .then(([quizsNumbersRes, quizsNumbersErr]) => {
                if (quizsNumbersErr) { console.log(quizsNumbersErr, 'err getting quizsNumbersRes') }
                this.quizsNumbers = quizsNumbersRes
            });


        Auth.superAuthFetch(`/api/Facts/getDangerRatingNumbers?chosenMonth=${chosenMonth}&&chosenYear=${chosenYear}&&client=${client}`, null, true)
            .then(([dangerRatingNumbersRes, dangerRatingNumbersErr]) => {
                if (dangerRatingNumbersErr) { console.log(dangerRatingNumbersErr, 'err getting dangerRatingNumbersRes') }
                this.dangerRatingNumbers = dangerRatingNumbersRes;
            });

        Auth.superAuthFetch(`/api/Facts/getQuizeAvgTime?chosenMonth=${chosenMonth}&&chosenYear=${chosenYear}&&client=${client}`, null, true)
            .then(([quizeAvgTimeRes, quizeAvgTimeErr]) => {
                if (quizeAvgTimeErr) { console.log(quizeAvgTimeErr, 'err getting dangerRatingNumbersRes') }
                this.quizeAvgTime = quizeAvgTimeRes && quizeAvgTimeRes[0].avg;
            });

        Auth.superAuthFetch(`/api/Facts/getMoreStatistics?chosenMonth=${chosenMonth}&&chosenYear=${chosenYear}&&client=${client}`, null, true)
            .then(([moreStatisticsRes, moreStatisticsErr]) => {
                if (moreStatisticsErr) { console.log(moreStatisticsErr, 'err getting moreStatisticsRes') }
                this.moreStatistics = moreStatisticsRes;
            });

        Auth.superAuthFetch(`/api/Facts/getViolenceTypes?chosenMonth=${chosenMonth}&&chosenYear=${chosenYear}&&client=${client}`, null, true)
            .then(([violenceTypesRes, violenceTypesErr]) => {
                if (violenceTypesErr) { console.log(violenceTypesErr, 'err getting violenceTypesErr') }
                this.violenceTypes = violenceTypesRes;
            });


        Auth.superAuthFetch(`/api/Facts/getActivityTimes?chosenMonth=${chosenMonth}&&chosenYear=${chosenYear}&&client=${client}`, null, true)
            .then(([activityTimesRes, activityTimesErr]) => {
                if (activityTimesErr) { console.log(activityTimesErr, 'err getting activityTimesErr') }
                this.activityTimes = activityTimesRes
            });
    }
}

decorate(FactsStore, {
    getStatistics: action,
    dangerRatingChartData: observable,
    dangerRatingNumbers: observable,
    quizsNumbers: observable,
    quizeAvgTime: observable,
    moreStatistics: observable,
    violenceTypes: observable,
    activityTimes: observable
});

export default new FactsStore();
