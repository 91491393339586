export default {
    quiz_details_keys: {
        questions_answers: "questions_answers",
        start_time: "start_time",
        exit_time: "exit_time",
        exit_status: "exit_status",
        danger_rating: "danger_rating"
    },
    LANGUAGE: {
        he: "עברית",
        ar: "ערבית",
        en: "אנגלית",
        ru: "רוסית",
        am: "אמהרית"
    },
    dir: {
        he: "rtl",
        ar: "rtl",
        en: "ltr",
        ru: "ltr",
        am: "ltr"
    },
    exit_statuses: {
        emergency: "emergency",
        timeout: "timeout",
        done: "done",
        // refresh: "refresh"
    },
    genders_and_times: {
        avg_time_spent: "avg_time_spent",
        victim_gender_count: "victim_gender_count",
        offender_gender_count: "offender_gender_count"
    },
    violence_types: {
        emotional_psychological: "emotional_psychological",
        physical: "physical",
        sexual: "sexual",
        financial: "financial"
    },
    filters: {
        today: "today",
        yesterday: "yesterday",
        week: "week",
        month: "month",
        personalSelect: "personalSelect",
        all: "all",
        male: "male",
        female: "female",
        year: "year",
        day: "day",
        orthodox: "orthodox",
        meuhedet: "meuhedet",
        ministryOfHealth:"ministry-of-health"
    }
}