import React, { Suspense, useEffect } from 'react';
import { Redirect, Switch, Route, withRouter } from "react-router-dom";
import Login from './scenes/Login';
import MainBar from './components/MainBar';
import { PrivateRoute, HomeRoute } from './modules/auth/PrivateRoute';
import loadable from '@loadable/component';
import './App.scss';
import Auth from './modules/auth/Auth';
import ResetPassword from './scenes/ResetPassword';
import NoInternet from './scenes/NoInternet';

const Lobby = loadable(() => import('./scenes/Lobby'));
const QuizDetails = loadable(() => import('./scenes/QuizDetails'));
const Statistics = loadable(() => import('./scenes/Statistics'));
// const Questions = loadable(() => import('./scenes/Questions'));

const path = process.env.PUBLIC_URL;

const App = withRouter(({ history: { push } }) => {

    useEffect(() => {
        Auth.setPath(path);
        if (!navigator.onLine) push('/no-internet');
        const eventUrls = [['online', () => push('/')], ['offline', () => push('/no-internet')]];
        eventUrls.forEach(([event, func]) => window.addEventListener(event, func));
        return () => eventUrls.forEach(([event, func]) => window.removeEventListener(event, func));
    }, []);

    const homePages = { Login, Statistics };

    return (
        <div className="app-container">
            <Suspense fallback={<div>Loading...</div>}>
                <div>
                    {/* <Redirect exact from="/" to="/admin" /> */}

                    {Auth.isAuthenticated() && <Route path="/(|quiz-details|lobby|questions)/" render={props => <MainBar {...props} />} />}
                    <Switch>
                        <HomeRoute exact path="/" component={() => <Login basePath={path} />} comps={homePages} />

                        <Route path="/r-password" component={() => <ResetPassword basePath={path} />} />
                        <Route path="/no-internet" component={NoInternet} />

                        <PrivateRoute exact path="/quiz-details/:q_id" compName='QuizDetails' component={QuizDetails} />

                        <PrivateRoute exact path="/lobby" compName='Lobby' component={Lobby} />
                        {/* <PrivateRoute exact path="/questions" compName='Questions' component={Questions} /> */}

                        <Redirect to='/' />
                    </Switch>
                </div>
            </Suspense>
        </div>
    );
})

export default App;
